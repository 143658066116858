/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import 'bootstrap'

const onInitialClientRender = () => {
    // fall-through for netlify login
    if (window.netlifyIdentity) {
        window.netlifyIdentity.on("init", user => {
            if (!user) {
                window.netlifyIdentity.on("login", () => {
                    document.window.location.href = "/admin/";
                });
            }
        });
    }

}
export {
    onInitialClientRender
}
